var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("select2", {
    class: _vm.inputclass,
    attrs: {
      options: _vm.options,
      value: _vm.value,
      settings: {
        width: "100%",
        allowClear: _vm.emptyname != null,
        placeholder: _vm.emptyname,
      },
    },
    on: {
      change: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }