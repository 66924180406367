var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "input-group input-group-sm ml-auto mg-r-20" },
    [
      _c("div", { staticClass: "input-group-prepend" }, [
        _c(
          "span",
          {
            staticClass: "input-group-text use-pointer",
            on: {
              click: function ($event) {
                _vm.currentSearch = ""
              },
            },
          },
          [_c("i", { staticClass: "fas fa-times tx-12 lh-0 op-6" })]
        ),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.currentSearch,
            expression: "currentSearch",
          },
        ],
        staticClass: "form-control",
        attrs: {
          type: "text",
          id: "search",
          name: "search",
          placeholder: _vm.$t("actions.search"),
        },
        domProps: { value: _vm.currentSearch },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.currentSearch = $event.target.value
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }