var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    directives: [
      {
        name: "validate",
        rawName: "v-validate",
        value: _vm.validation,
        expression: "validation",
      },
    ],
    staticClass: "form-control form-control-sm",
    class: _vm.inputclass,
    attrs: { type: "text", name: _vm.name, readonly: _vm.readonly },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }