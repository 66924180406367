var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("togglebutton", {
    class: _vm.inputclass,
    attrs: {
      value: _vm.value,
      sync: true,
      labels: { checked: "Ja", unchecked: "Nee" },
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }