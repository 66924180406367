var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "row" }, [
    _c("label", { staticClass: "col form-control-label" }, [
      _vm._v(_vm._s(_vm.name) + ":"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-8 mg-t-10 mg-sm-t-0" }, [
      _c("label", { staticClass: "col form-control-label tx-gray-800" }, [
        _vm._v(_vm._s(_vm.value)),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }