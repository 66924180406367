var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.totalPages > 1
    ? _c(
        "div",
        {
          staticClass:
            "pagingfooter bg-white bd mg-t-15 pd-10 d-flex align-items-center justify-content-end",
        },
        [
          _c(
            "nav",
            {
              staticClass: "ml-auto mg-r-20",
              attrs: { "aria-label": "Page navigation" },
            },
            [
              _c(
                "ul",
                { staticClass: "pagination pagination-basic mg-b-0" },
                [
                  _vm.currentPage == 0
                    ? [_vm._m(0), _vm._v(" "), _vm._m(1)]
                    : [
                        _c("li", { staticClass: "page-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "page-link",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setCurrentPage(0)
                                },
                              },
                            },
                            [_vm._v("≪")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "page-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "page-link",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setCurrentPage(_vm.currentPage - 1)
                                },
                              },
                            },
                            [_vm._v("<")]
                          ),
                        ]),
                      ],
                  _vm._v(" "),
                  _vm._l(_vm.pages, function (page) {
                    return _c(
                      "li",
                      {
                        key: page.value,
                        staticClass: "page-item",
                        class: { active: page.value == _vm.currentPage },
                        attrs: { id: "1" },
                      },
                      [
                        page.value == _vm.currentPage
                          ? _c(
                              "button",
                              {
                                staticClass: "page-link",
                                attrs: { type: "button" },
                              },
                              [_vm._v(_vm._s(page.text))]
                            )
                          : _c(
                              "button",
                              {
                                staticClass: "page-link",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.setCurrentPage(page.value)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(page.text))]
                            ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _vm.currentPage == _vm.totalPages - 1
                    ? [_vm._m(2), _vm._v(" "), _vm._m(3)]
                    : [
                        _c("li", { staticClass: "page-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "page-link",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setCurrentPage(_vm.currentPage + 1)
                                },
                              },
                            },
                            [_vm._v(">")]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", { staticClass: "page-item" }, [
                          _c(
                            "button",
                            {
                              staticClass: "page-link",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.setCurrentPage(_vm.totalPages - 1)
                                },
                              },
                            },
                            [_vm._v("≫")]
                          ),
                        ]),
                      ],
                ],
                2
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", { staticClass: "page-item" }, [
      _c(
        "button",
        { staticClass: "page-link", attrs: { type: "button", disabled: "" } },
        [_vm._v("≪")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", { staticClass: "page-item" }, [
      _c(
        "button",
        { staticClass: "page-link", attrs: { type: "button", disabled: "" } },
        [_vm._v("<")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", { staticClass: "page-item" }, [
      _c(
        "button",
        { staticClass: "page-link", attrs: { type: "button", disabled: "" } },
        [_vm._v(">")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("li", { staticClass: "page-item" }, [
      _c(
        "button",
        { staticClass: "page-link", attrs: { type: "button", disabled: "" } },
        [_vm._v("≫")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }