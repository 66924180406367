var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "br-pageheader pd-y-5 pd-l-20" }, [
    _c(
      "nav",
      { staticClass: "breadcrumb pd-0 mg-0 tx-12" },
      [
        _c(
          "router-link",
          { staticClass: "breadcrumb-item", attrs: { to: "/", exact: true } },
          [_vm._v(_vm._s(_vm.$t("title.home")))]
        ),
        _vm._v(" "),
        _vm._t("default"),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }