var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vue-datepicker-local", {
    attrs: {
      value: _vm.value,
      "input-class": "form-control form-control-sm wd-80 " + _vm.inputclass,
      format: _vm.$t("format.date"),
      local: _vm.local,
      disabled: _vm.disabled,
      clearable: _vm.clearable,
      "disabled-date": _vm.disabledDate,
      disabledate: _vm.disabledate,
    },
    on: { input: _vm.inputChanged },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }