var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "button",
      { staticClass: "btn btn-success btn-icon", attrs: { type: "submit" } },
      [_c("div", [_c("i", { staticClass: "fas fa-save" })])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }