var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.initialized
    ? _c("div", [
        _c(
          "div",
          { staticClass: "br-logo" },
          [
            _c("router-link", { attrs: { to: "/", exact: true } }, [
              _c("img", {
                staticClass: "img-fluid",
                attrs: { src: "/images/logo.png", alt: "Logo" },
              }),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "br-sideleft overflow-y-auto" }, [
          _c("label", { staticClass: "sidebar-label pd-x-15 mg-t-20" }, [
            _vm._v(_vm._s(_vm.$t("title.navigation"))),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "br-sideleft-menu" },
            [
              _c(
                "router-link",
                {
                  staticClass: "br-menu-link",
                  attrs: {
                    to: "/",
                    exact: true,
                    "active-class": "active show-sub",
                  },
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-home tx-22",
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("title.home"))),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.menudata.companies.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "br-menu-link",
                      class: {
                        "active show-sub":
                          _vm.subIsActive(_vm.$t("path.companies")) ||
                          _vm.subIsActive(_vm.$t("path.crmcompanies")),
                      },
                      attrs: { href: "javascript:;" },
                    },
                    [
                      _c("div", { staticClass: "br-menu-item" }, [
                        _c("i", {
                          staticClass: "menu-item-icon icon ion-ios-list tx-20",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("title.companies"))),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "menu-item-arrow fa fa-angle-down",
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menudata.companies.length > 0
                ? _c(
                    "ul",
                    { staticClass: "br-menu-sub nav flex-column" },
                    _vm._l(_vm.menudata.companies, function (item, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: item.name },
                                "active-class": "active",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("title." + item.title)) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menudata.reports.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "br-menu-link",
                      class: {
                        "active show-sub": _vm.subIsActive(
                          _vm.$t("path.reports")
                        ),
                      },
                      attrs: { href: "javascript:;" },
                    },
                    [
                      _c("div", { staticClass: "br-menu-item" }, [
                        _c("i", {
                          staticClass: "menu-item-icon icon ion-ios-list tx-20",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("title.reports"))),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "menu-item-arrow fa fa-angle-down",
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menudata.reports.length > 0
                ? _c(
                    "ul",
                    { staticClass: "br-menu-sub nav flex-column" },
                    _vm._l(_vm.menudata.reports, function (item, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: item.name },
                                "active-class": "active",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("title." + item.title)) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menudata.timeregistration.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "br-menu-link",
                      class: {
                        "active show-sub": _vm.subIsActive(
                          _vm.$t("path.timeregistration")
                        ),
                      },
                      attrs: { href: "javascript:;" },
                    },
                    [
                      _c("div", { staticClass: "br-menu-item" }, [
                        _c("i", {
                          staticClass: "menu-item-icon icon ion-ios-list tx-20",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("title.timeregistration"))),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "menu-item-arrow fa fa-angle-down",
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menudata.timeregistration.length > 0
                ? _c(
                    "ul",
                    { staticClass: "br-menu-sub nav flex-column" },
                    _vm._l(
                      _vm.menudata.timeregistration,
                      function (item, index) {
                        return _c(
                          "li",
                          { key: index, staticClass: "nav-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "nav-link",
                                attrs: {
                                  to: { name: item.name },
                                  "active-class": "active",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.$t("title." + item.title)) +
                                    " "
                                ),
                                item.count && _vm.bedgecount > 0
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "badge badge-secondary",
                                        staticStyle: { "margin-left": "5px" },
                                      },
                                      [_vm._v(_vm._s(_vm.bedgecount))]
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ],
                          1
                        )
                      }
                    ),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menudata.invoicing.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "br-menu-link",
                      class: {
                        "active show-sub": _vm.subIsActive(
                          _vm.$t("path.settings_invoicing")
                        ),
                      },
                      attrs: { href: "javascript:;" },
                    },
                    [
                      _c("div", { staticClass: "br-menu-item" }, [
                        _c("i", {
                          staticClass: "menu-item-icon icon ion-ios-list tx-20",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("title.invoicing"))),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "menu-item-arrow fa fa-angle-down",
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.menudata.invoicing.length > 0
                ? _c(
                    "ul",
                    { staticClass: "br-menu-sub nav flex-column" },
                    _vm._l(_vm.menudata.invoicing, function (item, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: item.name },
                                "active-class": "active",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("title." + item.title)) +
                                  " "
                              ),
                              item.count && _vm.bedgecount > 0
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-secondary",
                                      staticStyle: { "margin-left": "5px" },
                                    },
                                    [_vm._v(_vm._s(_vm.bedgecount))]
                                  )
                                : _vm._e(),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdministrator && _vm.menudata.settings.length > 0
                ? _c(
                    "a",
                    {
                      staticClass: "br-menu-link",
                      class: {
                        "active show-sub": _vm.subIsActive(
                          _vm.$t("path.settings")
                        ),
                      },
                      attrs: { href: "javascript:;" },
                    },
                    [
                      _c("div", { staticClass: "br-menu-item" }, [
                        _c("i", {
                          staticClass:
                            "menu-item-icon icon ion-ios-settings tx-20",
                        }),
                        _vm._v(" "),
                        _c("span", { staticClass: "menu-item-label" }, [
                          _vm._v(_vm._s(_vm.$t("title.settings"))),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          staticClass: "menu-item-arrow fa fa-angle-down",
                        }),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isAdministrator && _vm.menudata.settings.length > 0
                ? _c(
                    "ul",
                    { staticClass: "br-menu-sub nav flex-column" },
                    _vm._l(_vm.menudata.settings, function (item, index) {
                      return _c(
                        "li",
                        { key: index, staticClass: "nav-item" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "nav-link",
                              attrs: {
                                to: { name: item.name, params: item.params },
                                "active-class": "active",
                              },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.$t("title." + item.title)) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                    0
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "br-menu-link",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.logout.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "br-menu-item" }, [
                    _c("i", {
                      staticClass: "menu-item-icon icon ion-ios-exit tx-22",
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "menu-item-label" }, [
                      _vm._v(_vm._s(_vm.$t("actions.logout"))),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }