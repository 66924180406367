var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    class: _vm.inputclass,
    attrs: { type: "checkbox", "true-value": true, "false-value": false },
    domProps: { checked: _vm.value },
    on: {
      change: (e) => {
        _vm.$emit("input", e.target.checked)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }