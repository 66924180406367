var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("vue-autonumeric", {
    staticClass: "form-control form-control-sm text-right",
    class: _vm.widthClass,
    attrs: {
      id: _vm.uniqid,
      value: _vm.value == null ? "" : _vm.value,
      options: _vm.options,
      readonly: _vm.readonly,
    },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }