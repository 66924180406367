var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "d-flex pos-relative align-items-center",
      class: _vm.heightClass,
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "sk-double-bounce" }, [
      _c("div", { staticClass: "sk-child sk-double-bounce1 bg-gray-800" }),
      _vm._v(" "),
      _c("div", { staticClass: "sk-child sk-double-bounce2 bg-gray-800" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }