var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "button",
    {
      staticClass: "dropdown-item text-danger",
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [_vm._v("\n    " + _vm._s(_vm.$t("actions.delete")) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }