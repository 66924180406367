var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.initialized
    ? _c("select2", {
        class: _vm.inputclass,
        attrs: {
          options: _vm.options,
          value: _vm.value,
          settings: { width: "100%" },
        },
        on: {
          change: function ($event) {
            _vm.$emit("input", $event ? parseInt($event) : null)
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }