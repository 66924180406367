var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("select2", {
    directives: [
      {
        name: "validate",
        rawName: "v-validate",
        value: _vm.validate,
        expression: "validate",
      },
    ],
    class: _vm.inputclass,
    attrs: {
      options: _vm.options,
      value: _vm.value,
      settings: {
        width: "100%",
        allowClear: _vm.emptyname != null,
        placeholder: _vm.emptyname,
        tags: _vm.tags,
        multiple: _vm.multiple,
      },
      "data-vv-name": "field",
    },
    on: {
      change: function ($event) {
        _vm.$emit("input", $event ? parseInt($event) : null)
      },
      select: function ($event) {
        return _vm.$emit("select", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }